var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleEx,
            width: "1000px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
            title: "Gửi thông báo qua App cho học sinh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleEx = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createStudentNotify",
              attrs: {
                "label-width": "140px",
                model: _vm.createStudentNotify,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Tiêu đề", prop: "sendTitle" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Nhập tiêu đề" },
                              model: {
                                value: _vm.createStudentNotify.sendTitle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createStudentNotify,
                                    "sendTitle",
                                    $$v
                                  )
                                },
                                expression: "createStudentNotify.sendTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Nội dung", prop: "sendContent" } },
                          [
                            _c("el-input", {
                              attrs: {
                                rows: 15,
                                type: "textarea",
                                placeholder: "Nhập nội dung...",
                              },
                              model: {
                                value: _vm.createStudentNotify.sendContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createStudentNotify,
                                    "sendContent",
                                    $$v
                                  )
                                },
                                expression: "createStudentNotify.sendContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.sendNotificationTeacherOnApp
                          ? _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    on: { change: _vm.handleSelectChange },
                                    model: {
                                      value: _vm.checkSendAllTeacher,
                                      callback: function ($$v) {
                                        _vm.checkSendAllTeacher = $$v
                                      },
                                      expression: "checkSendAllTeacher",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Gửi kèm cho tất cả giáo viên trong lớp"
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-checkbox",
                                  {
                                    on: { change: _vm.handleSelectChangeOther },
                                    model: {
                                      value: _vm.checkSendTeacher,
                                      callback: function ($$v) {
                                        _vm.checkSendTeacher = $$v
                                      },
                                      expression: "checkSendTeacher",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Gửi kèm cho từng giáo viên trong lớp"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkSendTeacher === true
                          ? _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { "min-width": "150px" },
                                    attrs: {
                                      multiple: "",
                                      size: "medium",
                                      placeholder: "Chọn lớp",
                                    },
                                    on: { change: _vm.handleSelectChangeClass },
                                    model: {
                                      value: _vm.listIdClass,
                                      callback: function ($$v) {
                                        _vm.listIdClass = $$v
                                      },
                                      expression: "listIdClass",
                                    },
                                  },
                                  _vm._l(
                                    _vm.listClassSelect,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.className,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { "min-width": "200px" },
                                    attrs: {
                                      multiple: "",
                                      size: "medium",
                                      placeholder: "Chọn giáo viên",
                                    },
                                    on: {
                                      change: _vm.handleSelectChangeTeacher,
                                    },
                                    model: {
                                      value: _vm.listIdTeacher,
                                      callback: function ($$v) {
                                        _vm.listIdTeacher = $$v
                                      },
                                      expression: "listIdTeacher",
                                    },
                                  },
                                  _vm._l(
                                    _vm.listTeacherSelect,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "File đính kèm",
                              prop: "attachFile",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                attrs: {
                                  action: "",
                                  "on-remove": _vm.handleRemove,
                                  "before-remove": _vm.beforeRemove,
                                  "on-change": _vm.toggleUpload,
                                  "auto-upload": false,
                                  multiple: "",
                                  limit: 5,
                                  "on-exceed": _vm.handleExceed,
                                  "file-list": _vm.fileList,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "mini", type: "primary" } },
                                  [_vm._v("Tải lên")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-infor-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("createStudentNotify")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("createStudentNotify")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Gửi")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }